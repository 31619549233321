import React, { Component } from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import { Link } from "gatsby"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
import { hasScript } from "../utils/has-script"

class ContactForm extends Component {
  constructor(props) {
    super(props)
    // this.state = {
    //   fullName: "",
    //   fullNameError: "",
    //   emailAddress: "",
    //   emailAddressError: "",
    //   message: "",
    //   messageError: "",
    //   errors: { fullName: true, email: true, message: true },
    // }
    // this.nameInput = React.createRef()
    // this.emailInput = React.createRef()
    // this.messageInput = React.createRef()
    // this.handleValidation = this.handleValidation.bind(this)
    // this.handleSubmit = this.handleSubmit.bind(this)
  }

  // onChange(value) {
  //   console.log("Captcha value:", value)
  // }

  // handleSubmit(event) {
  //   event.preventDefault()

  //   // If values are empty, check each field.
  //   if (
  //     this.state.fullName === "" &&
  //     this.state.emailAddress === "" &&
  //     this.state.message === ""
  //   ) {
  //     this.handleValidation(this.nameInput.current)
  //     this.handleValidation(this.emailInput.current)
  //     this.handleValidation(this.messageInput.current)
  //   }

  //   var api_key = "a5084b10009a98653d2d61fcdcd90a53-09001d55-4ddd0ad2"
  //   var domain = "sandbox063a4d9c8cd14396b2b86740d7be8b35.mailgun.org"
  //   axios({
  //     method: "post",
  //     url: `https://api.eu.mailgun.net/v3/${domain}/messages`,
  //     auth: {
  //       username: "api",
  //       password: api_key,
  //     },
  //     params: {
  //       from:
  //         "Awesome Development Team <noreply@sandbox063a4d9c8cd14396b2b86740d7be8b35.mailgun.org>",
  //       to: "rebecca@insitesdigital.com",
  //       subject: "Hello",
  //       text: "Welcome to the team!",
  //     },
  //   }).then(
  //     response => {
  //       console.log(response)
  //     },
  //     reject => {
  //       console.log(reject)
  //     }
  //   )

  //   // if (Object.values(this.state.errors).indexOf(true) > -1) {
  //   //   console.log("there are errors")
  //   //   /*
  //   //     TODO:

  //   //     1) Add in mailgun solution - or something similar
  //   //     2) Get Captcha working
  //   //   */
  //   // }
  // }

  // handleValidation(event) {
  //   let currentErrors = this.state.errors
  //   let errorMessage = ""
  //   let inputField = event.target ? event.target : event
  //   let error = hasScript(inputField.value)

  //   switch (inputField.name) {
  //     case "email":
  //       if (!error) {
  //         error = !/\S+@\S+\.\S+/.test(inputField.value)
  //       }

  //       if (error) {
  //         errorMessage = "Please enter a valid email"
  //       }
  //       currentErrors.email = error

  //       this.setState({ emailAddressError: errorMessage })
  //       break
  //     case "name":
  //       if (!error) {
  //         error = inputField.value.length < 2
  //       }
  //       currentErrors.fullName = error
  //       if (error) {
  //         errorMessage = "Please enter your full name"
  //       }
  //       this.setState({ fullNameError: errorMessage })
  //       break
  //     case "message":
  //       if (!error) {
  //         error = inputField.value.length < 10
  //         if (error) {
  //           errorMessage =
  //             inputField.value.length === 0
  //               ? "Please enter your message"
  //               : "Please enter a valid message"
  //         }
  //       } else {
  //         errorMessage = "Please enter a valid message"
  //       }
  //       currentErrors.message = error

  //       this.setState({ messageError: errorMessage })
  //       break
  //     default:
  //   }

  //   if (error) inputField.classList.add("error")
  //   else inputField.classList.remove("error")

  //   this.setState({ errors: currentErrors })
  // }

  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <section className="container-left container-right privacy contact">
          <h1>contact us</h1>
          <div className="two-col">
            {/* <div className="contact__form">
              <form className="flex-column" method="post" action="#">
                <div>
                  <label htmlFor="name">full name</label>
                </div>

                <div className="contact__input-container">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={this.state.fullName}
                    onChange={event => {
                      this.setState({ fullName: event.target.value })
                    }}
                    onBlur={this.handleValidation}
                    ref={this.nameInput}
                  />
                  <p className="error-message">{this.state.fullNameError}</p>
                </div>

                <div>
                  <label htmlFor="email">email address</label>
                </div>

                <div className="contact__input-container">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={event => {
                      this.setState({ email: event.target.value })
                    }}
                    value={this.state.email}
                    onBlur={this.handleValidation}
                    ref={this.emailInput}
                  />
                  <p className="error-message">
                    {this.state.emailAddressError}
                  </p>
                </div>

                <div>
                  <label htmlFor="message">message</label>
                </div>

                <div className="contact__input-container">
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    value={this.state.message}
                    onChange={event => {
                      this.setState({ message: event.target.value })
                    }}
                    onBlur={this.handleValidation}
                    ref={this.messageInput}
                  />
                  <p className="error-message">{this.state.messageError}</p>
                </div>

                <div>
                  <ReCAPTCHA
                    sitekey="6Ld4KcQUAAAAAO_22aJ4k8uNAudOXUDD7goJgo7h"
                    onChange={this.onChange}
                  />
                </div>
                <div>
                  <button
                    className="contact__submit"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    submit.
                  </button>
                </div>
              </form>
            </div> */}

            <div>
              <p>
                <strong>email:</strong>
              </p>
              <p>
                <a href="mailto:customerservices@ceutahealthcare.com">
                  customerservices@ceutahealthcare.com
                </a>
              </p>
              <p>
                <strong>Telephone:</strong>
              </p>
              <p>0344 243 6661</p>
              {/* <p>Monday to Friday 9am to 5:30pm</p> */}

              <p>
                <strong>Address:</strong>
              </p>
              <p>
                Ceuta Customer Services, <br />
                Hill House, <br />
                41 Richmond Hill, <br />
                Bournemouth, <br />
                Dorset, <br />
                BH2 6HS
              </p>

              <p>
                <Link
                  to="/privacy-policy/"
                  style={{ display: "block", marginTop: "20px" }}
                >
                  View the Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default ContactForm
